import React, { FC, memo, useMemo } from 'react';

interface Props {
  color: string;
  size?: number;
  width?: number;
  height?: number;
  rotation: number;
  opacity: number;
  blur: number;
  className?: string;
}

const EllipsisCircle: FC<Props> = ({ color, size, width, height, rotation, opacity, blur, className }) => {
  const style = useMemo(() => {
    const dimensionStyle =
      size !== undefined
        ? { width: `${size}px`, height: `${size}px` }
        : {
            width: width ? `${width}px` : 'auto', // Use 'auto' if width is not provided
            height: height ? `${height}px` : 'auto' // Use 'auto' if height is not provided
          };

    return {
      borderRadius: '50%',
      background: color,
      ...dimensionStyle,
      transform: `rotate(${rotation}deg)`,
      filter: `blur(${blur}px)`,
      opacity: opacity,
      zIndex: -1
    };
  }, [color, size, width, height, rotation, opacity, blur]);

  return <div style={style} className={className} />;
};

export default memo(EllipsisCircle);
